.App {
  text-align: center;
}




textarea {
  background-color: #D4AF37;
  border-radius: 4px;
  border: 1px solid white;
  color:'white'
}

input {
  background-color: #D4AF37;
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid white;
  padding: 10px 15px;
  font-size: 14px;
  
}

input[type="submit"] {
  background-color: #D4AF37;
  color: white;
  text-transform: uppercase;
  border: none;
  padding: 20px;
  font-size: 16px;
  font-weight: 100;
  letter-spacing: 10px;
}

label {
  line-height: 2;
  text-align: left;
  display: block;
  margin-bottom: 5px;
  margin-top: 5px;
  color: black;
  font-size: 14px;
  font-weight: 200;
}

input, select, textarea{
  color: white;
}

textarea:focus, input:focus {
  color: white;
}

input:focus { 
  outline: none !important;
  border-color: #719ECE;
  box-shadow: 0 0 10px #719ECE;
}
textarea:focus { 
  outline: none !important;
  border-color: #719ECE;
  box-shadow: 0 0 10px #719ECE;
}

.carousel-root{
  height:100% !important
}
.carousel{
  height:100% !important
}
.carousel-slider{
  height:100% !important
}
.slider-wrapper{
  height:100% !important
}
.slide{ 
  height:100% !important
}
.carousel-style {
  height: 100% !important;
}
ul{
  height: 100% !important;
}


.fc {
  /* layout of immediate children */
  max-height: calc( 100vh - 200px ) !important;
}

li{
  list-style-position: outside !important; 
}
